import React from 'react';
import { graphql } from 'gatsby';
import racing from '../../img/racing.svg';
import PageWithTopTenComponent from './PageWithTopTenComponent';

// Small refactor
// Create one time useStaticQuery for every same component instead of writing all time 30 lines for the same thing.
const Racing = ({ data, pageContext }) => (
  <PageWithTopTenComponent data={data} pageContext={pageContext} img={racing} imgName="Racing" />
);

export default Racing;

export const pageQuery = graphql`
  query ($id: String!, $related: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        ...acf
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1980) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }

    allWordpressPost(filter: { wordpress_id: { in: $related } }, limit: 4) {
      edges {
        node {
          slug
          title
          excerpt
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 430) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "racing" } }) {
      nodes {
        id
        name
        slug
        items {
          type_label
          title
          object_slug
          url
          path
        }
      }
    }
  }
`;
